import React from 'react';
import { Layout, Menu } from 'antd';
import { BellOutlined, EyeOutlined, RadarChartOutlined, SettingOutlined, HomeOutlined, CreditCardOutlined } from '@ant-design/icons';

const { Sider } = Layout;

function Sidebar({ activePage, setActivePage, collapsed, setCollapsed }) {
    return (
        <Sider trigger={null} collapsible collapsed={collapsed} breakpoint="lg"
            onBreakpoint={broken => setCollapsed(broken)}>
            <div className="logo" style={{
                height: '64px',
                margin: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img
                    src="/images/logo.jpeg"
                    alt="RecruitSpy Logo"
                    style={{
                        height: collapsed ? '32px' : '48px',
                        transition: 'all 0.3s'
                    }}
                />
            </div>
            <Menu theme="dark" mode="inline" selectedKeys={[activePage]}>
                <Menu.Item key="home" icon={<HomeOutlined />} onClick={() => setActivePage('home')}>
                    Home
                </Menu.Item>
                <Menu.Item key="settings" icon={<SettingOutlined />} onClick={() => setActivePage('settings')}>
                    Settings
                </Menu.Item>
                <Menu.Item key="subscription" icon={<CreditCardOutlined />} onClick={() => setActivePage('subscription')}>
                    Subscription
                </Menu.Item>
            </Menu>
        </Sider>
    );
}

export default Sidebar;