import React, { useState, useEffect, useCallback } from 'react';
import { Card, Table, message, Typography, Tag, Button, Row, Col, Statistic } from 'antd';
import { ReloadOutlined, RollbackOutlined } from '@ant-design/icons';
import api from '../utils/api';
import SpyButton from './SpyButton';

const { Title } = Typography;

function DataPage({ navigateToAlert }) {
    const [spies, setSpies] = useState([]);
    const [selectedSpy, setSelectedSpy] = useState(null);
    const [matchedJobs, setMatchedJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    });
    const [sorter, setSorter] = useState({
        field: 'datePosted',
        order: 'descend'
    });

    const fetchSpies = useCallback(() => {
        setLoading(true);
        api.getAlerts()
            .then(savedAlerts => {
                setSpies(savedAlerts);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching spies:', error);
                message.error('Failed to fetch spies');
                setLoading(false);
            });
    }, []);

    const fetchAllMatchedJobs = useCallback(() => {
        setLoading(true);
        api.getMatchedJobs(1, 1000000, 'datePosted', 'descend') // Fetch all jobs
            .then(response => {
                const jobsWithSpyInfo = response.jobs.map(job => ({
                    ...job,
                    spyId: job.alertId // Assuming alertId corresponds to spyId
                }));
                setMatchedJobs(jobsWithSpyInfo);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching all matched jobs:', error);
                message.error('Failed to fetch all matched jobs');
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchSpies();
        fetchAllMatchedJobs();
    }, [fetchSpies, fetchAllMatchedJobs]);

    const handleSpyClick = (spy) => {
        setSelectedSpy(spy);
        // Filter jobs for the selected spy
        const filteredJobs = matchedJobs.filter(job => job.spyId === spy.id);
        setPagination(prev => ({
            ...prev,
            current: 1,
            total: filteredJobs.length
        }));
    };

    const handleTableChange = (newPagination, filters, newSorter) => {
        setPagination(newPagination);
        setSorter({
            field: newSorter.field || 'datePosted',
            order: newSorter.order || 'descend'
        });
    };

    const handleRefresh = () => {
        fetchSpies();
        fetchAllMatchedJobs();
    };

    const renderDescription = (description) => {
        const cleanedDescription = description.replace(/^```html/, '').replace(/```$/, '').trim();
        return <div dangerouslySetInnerHTML={{ __html: cleanedDescription }} />;
    };

    const renderDatePosted = (datePosted) => {
        return datePosted === 'Unknown' ? 'Very recent' : datePosted;
    };

    const markAsViewed = async (record) => {
        try {
            await api.markJobAsViewed(selectedSpy.id, record.id);
            setMatchedJobs(prevJobs =>
                prevJobs.map(job =>
                    job.id === record.id ? { ...job, viewed: true } : job
                )
            );
        } catch (error) {
            console.error('Error marking job as viewed:', error);
            message.error('Failed to mark job as viewed');
        }
    };

    const columns = [
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'viewed',
            render: (viewed) => (
                viewed ?
                    <Tag color="default">Viewed</Tag> :
                    <Tag color="green">New</Tag>
            )
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            sorter: true,
        },
        {
            title: 'Job Title',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
        },
        {
            title: 'Posted Date',
            dataIndex: 'datePosted',
            key: 'datePosted',
            sorter: true,
            render: renderDatePosted
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            sorter: true,
        },
        {
            title: 'Employment Type',
            dataIndex: 'employmentType',
            key: 'employmentType',
            sorter: true,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <SpyButton onClick={() => navigateToAlert(record.alertId)}>
                    View Spy
                </SpyButton>
            ),
        },
    ];

    const getFilteredJobs = () => {
        if (!selectedSpy) return [];
        return matchedJobs.filter(job => job.spyId === selectedSpy.id);
    };

    return (
        <div style={{ padding: '24px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Title level={2}>{selectedSpy ? `Intel for ${selectedSpy.industry} - ${selectedSpy.jobTitle}` : 'Your Spies'}</Title>
                <SpyButton
                    icon={<ReloadOutlined />}
                    onClick={handleRefresh}
                    loading={loading}
                >
                    Refresh
                </SpyButton>
            </div>
            {selectedSpy ? (
                <>
                    <SpyButton
                        icon={<RollbackOutlined />}
                        onClick={() => setSelectedSpy(null)}
                        style={{ marginBottom: '16px' }}
                    >
                        Back to Spies
                    </SpyButton>
                    <Table
                        dataSource={getFilteredJobs()}
                        columns={columns}
                        rowKey="id"
                        loading={loading}
                        pagination={pagination}
                        onChange={handleTableChange}
                        locale={{
                            emptyText: "Your spy is gathering intel!"
                        }}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div>
                                    <p><strong>Description:</strong> {renderDescription(record.description)}</p>
                                    <p><strong>Related Spy ID:</strong> {record.alertId}</p>
                                </div>
                            ),
                            onExpand: (expanded, record) => {
                                if (expanded && !record.viewed) {
                                    markAsViewed(record);
                                }
                            },
                        }}
                    />
                </>
            ) : (
                <Row gutter={[16, 16]}>
                    {spies.map(spy => (
                        <Col xs={24} sm={12} md={8} lg={6} key={spy.id}>
                            <Card
                                title={`${spy.industry} - ${spy.jobTitle}`}
                                extra={<SpyButton onClick={() => handleSpyClick(spy)}>View Intel</SpyButton>}
                                hoverable
                            >
                                <Statistic
                                    title="Intel Count"
                                    value={matchedJobs.filter(job => job.spyId === spy.id).length}
                                    style={{ marginBottom: '16px' }}
                                />
                                <p><strong>Location:</strong> {spy.location}</p>
                                <p><strong>Max Age:</strong> {spy.maxAge} days</p>
                                <p><strong>Email Alerts:</strong> {spy.emailAlerts ? 'Yes' : 'No'}</p>
                                <p><strong>SMS Alerts:</strong> {spy.smsAlerts ? 'Yes' : 'No'}</p>
                            </Card>
                        </Col>
                    ))}
                </Row>
            )}
        </div>
    );
}

export default DataPage;