import { isDevelopment } from '../config/config';
import { msalInstance } from '../config/msalConfig';

const API_BASE_URL = isDevelopment ? "http://localhost:5173/api" : 'https://recruitspy.azurewebsites.net/api';

let msalInitialized = false;

const initializeMsal = async () => {
    if (!msalInitialized) {
        await msalInstance.initialize();
        msalInitialized = true;
    }
};

export const getUserId = async () => {
    await initializeMsal();
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        return accounts[0].homeAccountId;
    }
    throw new Error('No authenticated user found');
};

const api = {
    // Spies (Alerts) API
    getAlerts: async () => {
        const userId = await getUserId();
        const response = await fetch(`${API_BASE_URL}/spies/${userId}`);
        return response.json();
    },

    addAlert: async (alert) => {
        const userId = await getUserId();
        alert.userId = userId;
        const response = await fetch(`${API_BASE_URL}/spies`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(alert),
        });
        return response.json();
    },

    deleteAlert: async (alertId) => {
        const userId = await getUserId();
        await fetch(`${API_BASE_URL}/spies/${userId}/${alertId}`, {
            method: 'DELETE',
        });
    },

    // Settings API
    updateSettings: async (settings) => {
        const userId = await getUserId();
        settings.userId = userId;
        const response = await fetch(`${API_BASE_URL}/settings/${userId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(settings),
        });
        return response.json();
    },

    getSettings: async () => {
        const userId = await getUserId();
        const response = await fetch(`${API_BASE_URL}/settings/${userId}`);
        return response.json();
    },

    // Job Postings API
    getMatchedJobs: async (page = 1, pageSize = 10, sortField = 'postedDate', sortOrder = 'descend') => {
        const userId = await getUserId();
        const response = await fetch(`${API_BASE_URL}/jobpostings/matched/${userId}?page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}`);
        return response.json();
    },

    checkJobMatches: async () => {
        const response = await fetch(`${API_BASE_URL}/jobpostings/check-job-matches`, {
            method: 'POST',
        });
        return response.text();
    },

    markJobAsViewed: async (alertId, jobId) => {
        const userId = await getUserId();
        const response = await fetch(`${API_BASE_URL}/jobpostings/${userId}/${alertId}/${jobId}/viewed`, {
            method: 'POST',
        });
        //return response.json();
    },

    downloadIntel: async (alertId, pages, alertName = "") => {
        try {
            const userId = await getUserId();
            const response = await fetch(`${API_BASE_URL}/jobpostings/download/${userId}/${alertId}?pages=${encodeURIComponent(pages)}`, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Failed to download CSV');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `intel_${alertName}_${new Date().toISOString().split('T')[0]}.csv`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading CSV:', error);
            throw error; // Rethrow the error so it can be handled by the caller
        }
    },

    getHiringManagerPhoneNumber: async (alertId, managerId) => {
        const userId = await getUserId();
        const response = await fetch(`${API_BASE_URL}/jobpostings/${userId}/${alertId}/${managerId}/phone`);
        return response.json();
    },

    getMatchedJobsForSpy: async (alertId, page = 1, pageSize = 10, sortField = 'datePosted', sortOrder = 'descend') => {
        const userId = await getUserId();
        const response = await fetch(`${API_BASE_URL}/jobpostings/matched/${userId}/${alertId}?page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}`);
        return response.json();
    },

    getStripeConfig: async () => {
        const response = await fetch(`${API_BASE_URL}/subscription/config`);
        return await response.json();
    },

    createCheckoutSession: async (priceId) => {
        const userId = await getUserId();
        const response = await fetch(`${API_BASE_URL}/subscription/create-checkout-session`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ priceId, userId }),
        });
        return response.json();
    },

    checkSubscription: async () => {
        const userId = await getUserId();
        const response = await fetch(`${API_BASE_URL}/subscription/check/${userId}`);
        return response.json();
    },

    verifySubscription: async (sessionId) => {
        const response = await fetch(`${API_BASE_URL}/subscription/verify`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sessionId }),
        });
        return response.json();
    },

    associateSubscriptionWithUser: async (clientSecret, homeAccountToken) => {
        const response = await fetch(`${API_BASE_URL}/subscription/associate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${homeAccountToken}`
            },
            body: JSON.stringify({ clientSecret }),
        });
        if (!response.ok) {
            throw new Error('Failed to associate subscription with user');
        }
        return response.json();
    },

    getUserSubscription: async () => {
        const userId = await getUserId();
        const response = await fetch(`${API_BASE_URL}/subscription/user/${userId}`);
        return response.json();
    },

    getUserLimits: async () => {
        const userId = await getUserId();
        const response = await fetch(`${API_BASE_URL}/subscription/user-limits/${userId}`);
        return response.json();
    },
};

export default api;