import React, { useState, useCallback, useEffect } from 'react';
import { Layout, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import AppWithAuth from './AppWithAuth';
import Sidebar from './components/Sidebar';
import AlertsPage from './components/AlertsPage';
import DataPage from './components/DataPage';
import SettingsPage from './components/SettingsPage';
import LogoutButton from './components/LogoutButton';
import HomePage from './components/Home';
import PlanSelection from './components/PlanSelection';
import api from './utils/api';
import SubscriptionPage from './components/SubscriptionPage';
import { useUser } from './UserContext';

const { Header, Content } = Layout;
const { Text } = Typography;

function App() {
  const { user, updateUser } = useUser();
  const [activePage, setActivePage] = useState('home');
  const [collapsed, setCollapsed] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState(null);
  const [shouldHighlight, setShouldHighlight] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const { hasActiveSubscription } = await api.checkSubscription();
        setIsSubscribed(hasActiveSubscription);
      } catch (error) {
        console.error('Error checking subscription:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkSubscription();
  }, []);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const navigateToAlert = useCallback((alertId) => {
    console.log('Navigating to alert:', alertId);
    setActivePage('alerts');
    setSelectedAlertId(alertId);
    setShouldHighlight(true);
  }, []);

  const clearHighlight = useCallback(() => {
    console.log('Clearing highlight');
    setShouldHighlight(false);
  }, []);

  const handlePageChange = useCallback((page) => {
    console.log('Changing page to:', page);
    setActivePage(page);
    if (page !== 'alerts') {
      setSelectedAlertId(null);
      setShouldHighlight(false);
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isSubscribed) {
    return <PlanSelection />;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar
        activePage={activePage}
        setActivePage={handlePageChange}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            background: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {React.createElement(MenuOutlined, {
            className: 'trigger',
            onClick: toggle,
            style: { padding: '0 24px', fontSize: '18px', cursor: 'pointer', color: '#001529' }
          })}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            paddingRight: '24px'
          }}>
            <Text style={{ marginRight: '16px' }}>{user.email}</Text>
            <LogoutButton />
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          {activePage === 'home' && <HomePage />}
          {activePage === 'settings' && <SettingsPage />}
          {activePage === 'subscription' && <SubscriptionPage />}
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;