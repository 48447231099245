const majorCities = [
    'New York City, NY',
    'Los Angeles, CA',
    'Toronto, ON',
    'Chicago, IL',
    'Houston, TX',
    'Montreal, QC',
    'Mexico City, CDMX',
    'Phoenix, AZ',
    'Philadelphia, PA',
    'San Antonio, TX',
    'San Diego, CA',
    'Dallas, TX',
    'Vancouver, BC',
    'San Jose, CA',
    'Calgary, AB',
    'Austin, TX',
    'Edmonton, AB',
    'Fort Worth, TX',
    'Columbus, OH',
    'San Francisco, CA',
    'Seattle, WA',
    'Denver, CO',
    'Washington, DC',
    'Boston, MA',
    'El Paso, TX',
    'Nashville, TN',
    'Detroit, MI',
    'Oklahoma City, OK',
    'Portland, OR',
    'Las Vegas, NV',
    'Memphis, TN',
    'Louisville, KY',
    'Baltimore, MD',
    'Milwaukee, WI',
    'Albuquerque, NM',
    'Tucson, AZ',
    'Fresno, CA',
    'Sacramento, CA',
    'Kansas City, MO',
    'Miami, FL',
    'Ottawa, ON',
    'Winnipeg, MB',
    'Quebec City, QC',
    'Hamilton, ON',
    'Guadalajara, JAL',
    'Monterrey, NL',
    'Puebla, PUE',
    'Tijuana, BC',
    'León, GTO',
    'Juárez, CHIH',
    'Mississauga, ON',
    'Brampton, ON',
    'Surrey, BC',
    'Laval, QC',
    'Halifax, NS',
    'London, ON',
    'Markham, ON',
    'Vaughan, ON',
    'Gatineau, QC',
    'Saskatoon, SK',
    'Longueuil, QC',
    'Kitchener, ON',
    'Burnaby, BC',
    'Windsor, ON',
    'Regina, SK',
    'Richmond, BC',
    'Richmond Hill, ON',
    'Oakville, ON',
    'Burlington, ON',
    'Greater Sudbury, ON',
    'Sherbrooke, QC',
    'Oshawa, ON',
    'Saguenay, QC',
    'Lévis, QC',
    'Barrie, ON',
    'Kelowna, BC',
    'Abbotsford, BC',
    'Coquitlam, BC',
    'Trois-Rivières, QC',
    'St. John\'s, NL'
];

export default majorCities;