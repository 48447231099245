import React, { useState, useEffect } from 'react';
import { Card, Button, Statistic, Typography, Row, Col, Modal, Form, Input, Select, Slider, Switch, Tooltip, Spin } from 'antd';
import { DeleteOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import majorCities from '../utils/majorCities';
import styled from "styled-components";
import SpyModal from './SpyModal';
import SpyButton from './SpyButton';
import { SpySelect } from './SpyInputs';
import api from '../utils/api';

const { Title } = Typography;
const { Option } = Select;

const CardTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 55px;
  padding: 8px 0;
`;

const TitleText = styled.span`
  flex: 1;
  min-width: 0;
  white-space: normal;
  word-wrap: break-word;
  margin-right: 10px;
`;

const PulsingDot = styled.div`
  width: 10px;
  height: 10px;
  background: #52c41a;
  border-radius: 50%;
  margin-right: 15px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.7);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(82, 196, 26, 0);
    }
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
    }
  }
`;

function capitalizeWords(str) {
    return str.replace(/\b\w+/g, word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
}

function SpyCards({ alerts, onDeleteAlert, onViewIntel, onAddAlert, userLimits }) {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [maxAge, setMaxAge] = useState(7);
    const employeeRangeBounds = [0, 10000];
    const [employeeRange, setEmployeeRange] = useState([...employeeRangeBounds]);
    const [isLoading, setIsLoading] = useState(true);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
        setMaxAge(7);
        setEmployeeRange([0, 10000]);
    };

    const handleAddAlert = (values) => {
        const newAlert = {
            ...values,
            industry: capitalizeWords(values.industry),
            jobTitle: capitalizeWords(values.jobTitle),
            maxAge: values.maxAge || 7,
            emailAlerts: values.emailAlerts || false,
            smsAlerts: values.smsAlerts || false,
            minEmployees: employeeRange[0],
            maxEmployees: employeeRange[1] == employeeRangeBounds[1] ? null : employeeRange[1]
        };
        onAddAlert(newAlert);
        handleCancel();
    };

    const handleMaxAgeChange = (value) => {
        setMaxAge(value);
        form.setFieldsValue({ maxAge: value });
    };

    const handleEmployeeRangeChange = (value) => {
        setEmployeeRange(value);
        form.setFieldsValue({ employeeRange: value });
    };

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                    <Card
                        hoverable
                        onClick={showModal}
                        style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            cursor: 'pointer'
                        }}
                        bodyStyle={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <PlusOutlined style={{ fontSize: '32px', marginBottom: '16px' }} />
                        <Title level={4} style={{ margin: 0 }}>Add New Spy</Title>
                    </Card>
                </Col>
                {[...alerts].reverse().map(alert => (
                    <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6} key={alert.id}>
                        <Card
                            title={<CardTitleWrapper onClick={() => onViewIntel(alert)}>
                                <TitleText>{`${alert.industry} - ${alert.jobTitle}`}</TitleText>
                                <Tooltip title="Spy is active and gathering intelligence">
                                    <PulsingDot />
                                </Tooltip>
                            </CardTitleWrapper>}
                            extra={
                                <>
                                    <SpyButton icon={<DeleteOutlined />} onClick={() => onDeleteAlert(alert.id)} />
                                </>
                            }
                            hoverable
                        >
                            <div
                                onClick={() => onViewIntel(alert)}>
                                <Statistic
                                    title="Intel Count"
                                    value={alert.intelCount || 0}
                                    style={{ marginBottom: '16px' }}
                                />
                                <p><strong>Location:</strong> {alert.location}</p>
                                <p><strong>New Intel Must Be Posted Within:</strong> {alert.maxAge} days</p>
                                <p><strong>Company Employee Count:</strong> {alert.minEmployees ? alert.minEmployees : '0'} - {alert.maxEmployees ? alert.maxEmployees : employeeRangeBounds[1] + '+'}</p>
                                <p><strong>Email Alerts:</strong> {alert.emailAlerts ? 'Yes' : 'No'}</p>
                                <p><strong>SMS Alerts:</strong> {alert.smsAlerts ? 'Yes' : 'No'}</p>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Modal
                title={<Title level={3} style={{ marginBottom: '16px', marginTop: "0" }}>Add a Spy</Title>}
                visible={isModalVisible}
                onCancel={handleCancel}
                maskClosable={false}
                footer={null}
            >
                <Form form={form} onFinish={handleAddAlert} layout="vertical">
                    <Form.Item
                        name="industry"
                        rules={[{ required: true, message: 'Please input the industry!' }]}
                        label={
                            <span>
                                Industry
                                <Tooltip title="Specify the industry segment or sub-sector you want to monitor. Example: 'Artificial Intelligence' or 'Renewable Energy'">
                                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                </Tooltip>
                            </span>
                        }
                    >
                        <Input placeholder="Industry Segment or Sub-Sector" />
                    </Form.Item>
                    <Form.Item
                        name="jobTitle"
                        label={
                            <span>
                                Job Title
                                <Tooltip title="Optionally specify a job title to focus on. Example: 'Data Scientist' or 'Solar Panel Engineer'">
                                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                </Tooltip>
                            </span>
                        }
                    >
                        <Input placeholder="Job Title (optional)" />
                    </Form.Item>
                    <Form.Item
                        name="location"
                        rules={[{ required: true, message: 'Please select a location!' }]}
                        label={
                            <span>
                                Location
                                <Tooltip title="Select the city you want to monitor. This helps in gathering location-specific intelligence.">
                                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                </Tooltip>
                            </span>
                        }
                    >
                        <SpySelect
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a city"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {majorCities.map(city => (
                                <Option key={city} value={city}>{city}</Option>
                            ))}
                        </SpySelect>
                    </Form.Item>
                    <Form.Item
                        name="maxAge"
                        initialValue={7}
                        label={
                            <span>
                                {`Exclude intel that is older than: ${maxAge} day${maxAge > 1 ? 's' : ''}`}
                                <Tooltip title="Set the maximum age of intel you want to receive. This helps filter out outdated information.">
                                    <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                </Tooltip>
                            </span>
                        }
                    >
                        <Slider min={1} max={30} onChange={handleMaxAgeChange} value={maxAge} />
                    </Form.Item>
                    {userLimits && userLimits.advancedFilters && (
                        <>
                            <Form.Item
                                name="employeeRange"
                                label={
                                    <span>
                                        Company Size (number of employees)
                                        <Tooltip title="Set the range of company sizes you want to target. This helps focus on companies of specific scales.">
                                            <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                <Slider
                                    range
                                    min={employeeRangeBounds[0]}
                                    max={employeeRangeBounds[1]}
                                    step={100}
                                    value={employeeRange}
                                    onChange={handleEmployeeRangeChange}
                                    marks={{
                                        0: '0',
                                        2500: '2,500',
                                        5000: '5,000',
                                        7500: '7,500',
                                        10000: '10,000+'
                                    }}
                                />
                            </Form.Item>
                            <p>Selected range: {employeeRange[0]} - {employeeRange[1] === employeeRangeBounds[1] ? employeeRangeBounds[1].toString() + '+' : employeeRange[1]} employees</p>
                        </>
                    )}
                    {userLimits && !userLimits.advancedFilters && (
                        <p style={{ color: '#ff4d4f' }}>Advanced filters are not available in your current plan. Upgrade to enable this feature.</p>
                    )}
                    {userLimits && userLimits.receiveAlerts && (
                        <>
                            <Form.Item
                                name="emailAlerts"
                                valuePropName="checked"
                                label={
                                    <span>
                                        Receive email alerts for this spy
                                        <Tooltip title="Enable this to receive email notifications when new intelligence is gathered for this spy.">
                                            <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                name="smsAlerts"
                                valuePropName="checked"
                                label={
                                    <span>
                                        Receive SMS alerts for this spy
                                        <Tooltip title="Enable this to receive SMS notifications when new intelligence is gathered for this spy.">
                                            <InfoCircleOutlined style={{ marginLeft: 8 }} />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                <Switch />
                            </Form.Item>
                        </>
                    )}
                    {userLimits && !userLimits.receiveAlerts && (
                        <p style={{ color: '#ff4d4f' }}>Alerts are not available in your current plan. Upgrade to enable this feature.</p>
                    )}
                    <Form.Item>
                        {/* <Button type="dashed">Cancel</Button> */}
                        <Button type="primary" htmlType="submit">Add Spy</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default SpyCards;