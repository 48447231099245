// src/config/msalConfig.js

import { PublicClientApplication } from "@azure/msal-browser";
import { isDevelopment } from "./config";

export const msalConfig = {
    auth: {
        clientId: "e29f8395-8d6e-4b53-82db-c37cd4e9e9ee", // Replace with your Azure AD B2C client ID
        authority: "https://recruitspy.b2clogin.com/recruitspy.onmicrosoft.com/B2C_1_susi1",
        knownAuthorities: ["recruitspy.b2clogin.com"],
        redirectUri: isDevelopment ? "http://localhost:3000" : "https://app.recruitspy.ai", // Update this to your app's redirect URI
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Define login request object
export const loginRequest = {
    scopes: ["openid", "profile"],
};

// Define token request object (for API calls)
export const tokenRequest = {
    scopes: ["https://recruitspy.onmicrosoft.com/YOUR_API/YOUR_SCOPE"],
};