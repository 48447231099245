import React from 'react';
import { DatePicker, Select } from 'antd';
import styled from 'styled-components';

// Styled DatePicker
const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    background-color: #374151;
    border-color: #4b5563;

    input {
      color: #e5e7eb;

      &::placeholder {
        color: #9ca3af;
      }
    }

    .ant-picker-suffix {
      color: #e5e7eb;
    }
  }

  &.ant-picker:hover,
  &.ant-picker-focused {
    border-color: #60a5fa;
  }

  .ant-picker-panel-container {
    background-color: #1f2937;

    .ant-picker-panel {
      background-color: #1f2937;
      border-bottom: 1px solid #4b5563;
    }

    .ant-picker-header {
      color: #e5e7eb;
      border-bottom: 1px solid #4b5563;

      button {
        color: #e5e7eb;
      }
    }

    .ant-picker-content th {
      color: #9ca3af;
    }

    .ant-picker-cell {
      color: #e5e7eb;

      &:hover .ant-picker-cell-inner {
        background-color: #4b5563;
      }
    }

    .ant-picker-cell-in-view {
      color: #e5e7eb;
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: #1e3a8a;
    }
  }
`;

// Styled Select (for city autocomplete)
const StyledSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #374151;
    border-color: #4b5563;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #60a5fa;
    box-shadow: 0 0 0 2px rgba(96, 165, 250, 0.2);
  }

  .ant-select-selection-placeholder {
    color: #9ca3af;
  }

  .ant-select-selection-item {
    color: #e5e7eb;
  }

  .ant-select-arrow {
    color: #e5e7eb;
  }

  .ant-select-dropdown {
    background-color: #1f2937;
  }

  .ant-select-item {
    color: #e5e7eb;

    &:hover {
      background-color: #4b5563;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #1e3a8a;
    color: #e5e7eb;
  }
`;

function SpyDatePicker(props) {
    //return <StyledDatePicker {...props} />;
    return <DatePicker {...props} />;
}

function SpySelect(props) {
    //return <StyledSelect {...props} />;
    return <Select {...props} />;
}

export { SpyDatePicker, SpySelect };