import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Input, Slider, Table, message, Form, Typography, Switch, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import api from '../utils/api';
import majorCities from '../utils/majorCities';
import SpyButton from './SpyButton';
import { SpySelect } from './SpyInputs';

const { Title } = Typography;
const { Option } = Select;

function AlertsPage({ selectedAlertId, shouldHighlight, clearHighlight }) {
    const [form] = Form.useForm();
    const [alerts, setAlerts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [maxAge, setMaxAge] = useState(7);
    const [highlightedAlertId, setHighlightedAlertId] = useState(null);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    });
    const industryInputRef = useRef(null);
    const selectedRowRef = useRef(null);

    const fetchAlerts = useCallback(() => {
        setLoading(true);
        api.getAlerts()
            .then(savedAlerts => {
                setAlerts(savedAlerts);
                setPagination(prev => ({ ...prev, total: savedAlerts.length }));
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching alerts:', error);
                message.error('Failed to fetch alerts');
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);

    useEffect(() => {
        if (selectedAlertId && shouldHighlight) {
            const index = alerts.findIndex(alert => alert.id === selectedAlertId);
            if (index !== -1) {
                const page = Math.floor(index / pagination.pageSize) + 1;
                setPagination(prev => ({ ...prev, current: page }));
                setHighlightedAlertId(selectedAlertId);

                setTimeout(() => {
                    if (selectedRowRef.current) {
                        selectedRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }, 0);

                const timer = setTimeout(() => {
                    setHighlightedAlertId(null);
                    clearHighlight();
                }, 3000);

                return () => clearTimeout(timer);
            }
        }
    }, [selectedAlertId, shouldHighlight, alerts, pagination.pageSize, clearHighlight]);

    const addAlert = (values) => {
        const newAlert = {
            ...values,
            maxAge: values.maxAge || 7,
            emailAlerts: values.emailAlerts || false,
            smsAlerts: values.smsAlerts || false
        };
        setLoading(true);
        api.addAlert(newAlert)
            .then(() => {
                fetchAlerts();
                form.resetFields();
                setMaxAge(7);
                message.success('Spy added successfully');
                setTimeout(() => {
                    if (industryInputRef.current) {
                        industryInputRef.current.focus();
                    }
                }, 100)
            })
            .catch(error => {
                console.error('Error adding alert:', error);
                message.error('Failed to add alert');
                setLoading(false);
            });
    };

    const deleteAlert = (alertId) => {
        setLoading(true);
        api.deleteAlert(alertId)
            .then(() => {
                fetchAlerts();
                message.success('Alert deleted successfully');
            })
            .catch(error => {
                console.error('Error deleting alert:', error);
                message.error('Failed to delete alert. Please try again.');
                setLoading(false);
            });
    };

    const columns = [
        { title: 'Industry', dataIndex: 'industry', key: 'industry' },
        { title: 'Job Title', dataIndex: 'jobTitle', key: 'jobTitle' },
        { title: 'Location', dataIndex: 'location', key: 'location' },
        { title: 'Max Age (days)', dataIndex: 'maxAge', key: 'maxAge' },
        { title: 'Email Alerts', dataIndex: 'emailAlerts', key: 'emailAlerts', render: (text) => text ? 'Yes' : 'No' },
        { title: 'SMS Alerts', dataIndex: 'smsAlerts', key: 'smsAlerts', render: (text) => text ? 'Yes' : 'No' },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <SpyButton icon={<DeleteOutlined />} onClick={() => deleteAlert(record.id)} />
            ),
        },
    ];

    const handleMaxAgeChange = (value) => {
        setMaxAge(value);
        form.setFieldsValue({ maxAge: value });
    };

    const handleTableChange = (newPagination) => {
        setPagination(newPagination);
    };

    return (
        <div style={{ padding: '24px' }}>
            <Title level={2}>Your Spies</Title>
            <Table
                dataSource={alerts}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowClassName={(record) => record.id === highlightedAlertId ? 'ant-table-row-highlighted' : ''}
                onRow={(record) => ({
                    ref: record.id === highlightedAlertId ? selectedRowRef : null,
                })}
            />
            <Title level={3} style={{ marginTop: '24px' }}>Add a Spy</Title>
            <Form form={form} onFinish={addAlert} layout="vertical">
                <Form.Item name="industry" rules={[{ required: true, message: 'Please input the industry!' }]}>
                    <Input placeholder="Industry" ref={industryInputRef} />
                </Form.Item>
                <Form.Item name="jobTitle" rules={[{ required: true, message: 'Please input the job title!' }]}>
                    <Input placeholder="Job Title" />
                </Form.Item>
                <Form.Item name="location" rules={[{ required: true, message: 'Please select a location!' }]}>
                    <SpySelect
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {majorCities.map(city => (
                            <SpySelect.Option key={city} value={city}>{city}</SpySelect.Option>
                        ))}
                    </SpySelect>
                </Form.Item>
                <Form.Item name="maxAge" initialValue={7} label={`Max Age: ${maxAge} day${maxAge > 1 ? 's' : ''}`}>
                    <Slider min={1} max={30} onChange={handleMaxAgeChange} value={maxAge} />
                </Form.Item>
                <Form.Item name="emailAlerts" valuePropName="checked" label="Receive email alerts for this spy">
                    <Switch />
                </Form.Item>
                <Form.Item name="smsAlerts" valuePropName="checked" label="Receive SMS alerts for this spy">
                    <Switch />
                </Form.Item>
                <Form.Item>
                    <SpyButton type="primary" htmlType="submit" loading={loading}>Add Spy</SpyButton>
                </Form.Item>
            </Form>
        </div>
    );
}

export default AlertsPage;