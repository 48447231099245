import React, { useState, useEffect, useCallback } from 'react';
import { Button, message, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import api from '../utils/api';
import SpyCards from './SpyCards';
import SpyIntel from './SpyIntel';
import SpyButton from './SpyButton';

const { Title } = Typography;

function Home() {
    const [alerts, setAlerts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [refreshBehaviour, setRefreshBehaviour] = useState(null);
    const [pollId, setPollId] = useState();
    const [userLimits, setUserLimits] = useState(null);

    const fetchAlerts = useCallback(() => {
        setLoading(true);
        api.getAlerts()
            .then(savedAlerts => {
                setAlerts(savedAlerts);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching spies:', error);
                message.error('Failed to fetch spies');
                setLoading(false);
            });
    }, []);

    const fetchUserLimits = useCallback(() => {
        api.getUserLimits()
            .then(limits => {
                setUserLimits(limits);
            })
            .catch(error => {
                console.error('Error fetching user limits:', error);
                message.error('Failed to fetch user limits');
            });
    }, []);

    const instantiatePoll = () => {
        setPollId(setInterval(fetchAlerts, 30000));
    }

    useEffect(() => {
        fetchAlerts();
        fetchUserLimits();
        instantiatePoll();
    }, [fetchAlerts, fetchUserLimits]);

    const handleAddAlert = (newAlert) => {
        setLoading(true);
        api.addAlert(newAlert)
            .then(() => {
                fetchAlerts();
                message.success('Spy added successfully.');
            })
            .catch(error => {
                console.error('Error adding spy:', error);
                message.error('Failed to add spy');
                setLoading(false);
            });
    };

    const handleDeleteAlert = (alertId) => {
        setLoading(true);
        api.deleteAlert(alertId)
            .then(() => {
                fetchAlerts();
                message.success('Spy deleted successfully');
            })
            .catch(error => {
                console.error('Error deleting spy:', error);
                message.error('Failed to delete spy. Please try again.');
                setLoading(false);
            });
    };

    const handleAlertClick = (alert) => {
        clearInterval(pollId);
        setPollId(null);
        setSelectedAlert(alert);
    };

    const handleRefresh = () => {
        if (refreshBehaviour == null) {
            fetchAlerts();
        } else {
            refreshBehaviour();
        }
    };

    return (
        <div style={{ padding: '24px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Title level={2}>{selectedAlert ? `Intel for ${selectedAlert.industry} - ${selectedAlert.jobTitle}` : 'Your Spies'}</Title>
                <SpyButton
                    icon={<ReloadOutlined />}
                    onClick={handleRefresh}
                    loading={loading}
                >
                    Refresh
                </SpyButton>
            </div>
            {selectedAlert ? (
                <SpyIntel
                    selectedAlert={selectedAlert}
                    onBackToSpies={() => { setSelectedAlert(null); setRefreshBehaviour(null); instantiatePoll(); }}
                    refreshBehaviour={setRefreshBehaviour}
                    userLimits={userLimits}
                />
            ) : (
                <SpyCards
                    alerts={alerts}
                    onDeleteAlert={handleDeleteAlert}
                    onViewIntel={handleAlertClick}
                    onAddAlert={handleAddAlert}
                    userLimits={userLimits}
                />
            )}
        </div>
    );
}

export default Home;