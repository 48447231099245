import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from './config/msalConfig';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppWithAuth from './AppWithAuth';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import { UserProvider } from './UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <UserProvider>
    <Router>
      <Routes>
        <Route path="/" element={<AppWithAuth>
          <App />
        </AppWithAuth>} />
        <Route path="/subscription-success" element={<SubscriptionSuccess />}/>
      </Routes>
    </Router>
  </UserProvider>
  //</React.StrictMode>
);

reportWebVitals();