import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  &.ant-btn {
    background-color: ${props => props.type === 'primary' ? '#1e3a8a' : '#374151'};
    border-color: ${props => props.type === 'primary' ? '#1e3a8a' : '#4b5563'};
    color: #ffffff;  // Changed to white for better contrast

    &:hover,
    &:focus {
      background-color: ${props => props.type === 'primary' ? '#2563eb' : '#4b5563'};
      border-color: ${props => props.type === 'primary' ? '#2563eb' : '#6b7280'};
      color: #ffffff;  // Kept white for hover state
    }

    &:active {
      background-color: ${props => props.type === 'primary' ? '#1d4ed8' : '#6b7280'};
      border-color: ${props => props.type === 'primary' ? '#1d4ed8' : '#9ca3af'};
      color: #ffffff;  // Kept white for active state
    }
  }

  &.ant-btn-dashed {
    border-style: dashed;
  }

  &.ant-btn-link {
    color: #93c5fd;  // Lighter blue for link buttons
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:focus {
      color: #bfdbfe;  // Even lighter blue on hover
      background-color: rgba(147, 197, 253, 0.1);  // Slight background change for visibility
    }

    &:active {
      color: #60a5fa;
    }
  }

  &.ant-btn-text {
    color: #e5e7eb;
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:focus {
      color: #ffffff;
      background-color: rgba(75, 85, 99, 0.2);
    }

    &:active {
      color: #ffffff;
      background-color: rgba(75, 85, 99, 0.4);
    }
  }

  &.ant-btn-dangerous {
    background-color: #dc2626;
    border-color: #dc2626;
    color: #ffffff;

    &:hover,
    &:focus {
      background-color: #ef4444;
      border-color: #ef4444;
      color: #ffffff;
    }

    &:active {
      background-color: #b91c1c;
      border-color: #b91c1c;
    }
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    background-color: #4b5563;
    border-color: #6b7280;
    color: #9ca3af;
  }
`;

function SpyButton(props) {
    //return <StyledButton {...props} />;
    return <Button {...props} />;
}

export default SpyButton;