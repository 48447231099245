import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: #1f2937;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .ant-modal-header {
    background-color: #1e3a8a;
    border-bottom: 1px solid #4b5563;
  }

  .ant-modal-title {
    color: #e5e7eb;
  }

  .ant-modal-close {
    color: #e5e7eb;
  }

  .ant-modal-body {
    color: #e5e7eb;
  }

  .ant-modal-footer {
    border-top: 1px solid #4b5563;
  }

  .ant-btn {
    background-color: #374151;
    border-color: #4b5563;
    color: #e5e7eb;

    &:hover {
      background-color: #4b5563;
      border-color: #6b7280;
    }
  }

  .ant-btn-primary {
    background-color: #1e3a8a;
    border-color: #1e3a8a;

    &:hover {
      background-color: #2563eb;
      border-color: #2563eb;
    }
  }

  // Style for form elements inside the modal
  .ant-form-item-label > label {
    color: #e5e7eb;
  }

  .ant-input,
  .ant-input-number,
  .ant-select-selector,
  .ant-picker {
    background-color: #374151;
    border-color: #4b5563;
    color: #e5e7eb;

    &::placeholder {
      color: #9ca3af;
    }
  }

  .ant-select-arrow,
  .ant-picker-suffix {
    color: #e5e7eb;
  }

  .ant-checkbox-wrapper {
    color: #e5e7eb;
  }

  .ant-checkbox-inner {
    background-color: #374151;
    border-color: #4b5563;
  }
`;

function SpyModal(props) {
    //return <StyledModal {...props} />;
    return <Modal {...props} />;
}

export default SpyModal;