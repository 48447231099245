import React from 'react';
import { useMsal } from "@azure/msal-react";
import { Button } from "antd";

function LogoutButton() {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
    };

    return (
        <Button danger onClick={handleLogout}>
            Logout
        </Button>
    );
}

export default LogoutButton;