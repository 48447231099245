import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Typography } from 'antd';
import api from '../utils/api';
import LogoutButton from './LogoutButton';
import SpyButton from './SpyButton';

const { Title } = Typography;

function SettingsPage() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        api.getSettings()
            .then(savedSettings => {
                form.setFieldsValue(savedSettings);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching settings:', error);
                message.error('Failed to fetch settings');
                setLoading(false);
            });
    }, [form]);

    const handleSubmit = (values) => {
        setLoading(true);
        api.updateSettings(values)
            .then(() => {
                message.success('Settings updated successfully');
                setLoading(false);
            })
            .catch(error => {
                console.error('Error updating settings:', error);
                message.error('Failed to update settings');
                setLoading(false);
            });
    };

    return (
        <div style={{ padding: '24px' }}>
            <Title level={2}>Settings</Title>
            <Title level={4}>Notifications</Title>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="email"
                    label="Email address"
                    rules={[
                        { required: true, message: 'Please input your email address!' },
                        { type: 'email', message: 'Please enter a valid email address!' }
                    ]}
                >
                    <Input placeholder="Enter your email address" />
                </Form.Item>
                <Form.Item
                    name="phoneNumber"
                    label="Phone number"
                    rules={[
                        { required: true, message: 'Please input your phone number!' },
                        { pattern: /^\+?[1-9]\d{1,14}$/, message: 'Please enter a valid phone number!' }
                    ]}
                >
                    <Input placeholder="Enter your phone number" />
                </Form.Item>
                <Form.Item>
                    <SpyButton type="primary" htmlType="submit" loading={loading}>
                        Save Settings
                    </SpyButton>
                </Form.Item>
                <Form.Item>
                    <LogoutButton />
                </Form.Item>
            </Form>
        </div>
    );
}

export default SettingsPage;