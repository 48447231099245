import React, { useEffect, useState } from 'react';
import { useMsal, MsalProvider } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { msalInstance, loginRequest } from './config/msalConfig';
import { UserProvider, useUser } from './UserContext';

function AuthWrapper({ children }) {
    const { instance, inProgress, accounts } = useMsal();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { updateUser } = useUser();

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            if (accounts.length > 0) {
                setIsAuthenticated(true);
                const userEmail = accounts[0].username;
                updateUser({ email: userEmail });
            } else {
                instance.loginRedirect(loginRequest)
                    .catch((e) => {
                        console.error("Login failed", e);
                    });
            }
        }
    }, [instance, inProgress, accounts, updateUser]);

    if (isAuthenticated) {
        return children;
    }

    return null;
}

function AppWithAuth({ children }) {
    return (
        <MsalProvider instance={msalInstance}>
            <UserProvider>
                <AuthWrapper>
                    {children}
                </AuthWrapper>
            </UserProvider>
        </MsalProvider>
    );
}

export default AppWithAuth;