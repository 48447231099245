import React, { useEffect, useState } from 'react';
import { message, Typography, Layout } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import api from '../utils/api'; // Adjust this import path as needed
import { useMsal } from '@azure/msal-react'; // Import MSAL hook

const { Title } = Typography;
const { Content } = Layout;

const PlanSelection = ({ onPlanSelected }) => {
    const [stripe, setStripe] = useState(null);
    const { instance, accounts } = useMsal(); // Use MSAL hook to get instance and accounts

    useEffect(() => {
        initializeStripe();
    }, []);

    const initializeStripe = async () => {
        try {
            const { publishableKey, pricingTableId } = await api.getStripeConfig();
            const stripeInstance = await loadStripe(publishableKey);
            setStripe(stripeInstance);

            // Get the homeaccounttoken
            const account = accounts[0]; // Assuming the first account is the active one
            const homeAccountId = account.homeAccountId;

            // Encode the homeAccountId to create a valid client-reference-id
            const encodedHomeAccountId = encodeForStripe(homeAccountId);

            embedPricingTable(publishableKey, pricingTableId, encodedHomeAccountId);
        } catch (error) {
            message.error('Failed to initialize Stripe');
            console.error(error);
        }
    };

    const encodeForStripe = (input) => {
        return input.split('').map(char => {
            if (char.match(/[a-zA-Z0-9-_]/)) {
                return char;
            } else {
                return `_${char.charCodeAt(0).toString(16).padStart(4, '0')}_`;
            }
        }).join('');
    };

    const embedPricingTable = (publishableKey, pricingTableId, clientReferenceId) => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        const pricingTable = document.createElement('stripe-pricing-table');
        pricingTable.setAttribute('pricing-table-id', pricingTableId);
        pricingTable.setAttribute('publishable-key', publishableKey);

        // Add client-reference-id attribute with the encoded homeAccountId
        pricingTable.setAttribute('client-reference-id', clientReferenceId);

        const container = document.getElementById('pricing-table-container');
        if (container) {
            container.innerHTML = '';
            container.appendChild(pricingTable);
        }

        // Add event listener for successful checkout
        pricingTable.addEventListener('checkout-completed', handleCheckoutCompleted);
    };

    const handleCheckoutCompleted = async (event) => {
        const { clientSecret } = event.detail;

        try {
            // Get a fresh token
            const account = accounts[0];
            const tokenResponse = await instance.acquireTokenSilent({
                scopes: ["user.read"], // Adjust scopes as needed
                account: account
            });

            const homeAccountToken = tokenResponse.accessToken;

            // Send the token to your backend
            await api.associateSubscriptionWithUser(clientSecret, homeAccountToken);

            message.success('Subscription successfully associated with your account');
            if (onPlanSelected) {
                onPlanSelected();
            }
        } catch (error) {
            message.error('Failed to associate subscription with your account');
            console.error(error);
        }
    };

    return (
        <Layout>
            <Content style={{ padding: '50px 0', textAlign: 'center' }}>
                <Title level={2} style={{ marginBottom: '30px' }}>Select a Plan</Title>
                <div id="pricing-table-container"></div>
            </Content>
        </Layout>
    );
};

export default PlanSelection;