import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button, Tag, message, Divider, Modal, DatePicker, Spin, Input } from 'antd';
import { RollbackOutlined, PhoneOutlined, DownloadOutlined } from '@ant-design/icons';
import api from '../utils/api';
import SpyModal from './SpyModal';
import SpyButton from './SpyButton';

function SpyIntel({ selectedAlert, onBackToSpies, refreshBehaviour, userLimits }) {
    const [matchedJobs, setMatchedJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    });
    const [sorter, setSorter] = useState({
        field: 'datePosted',
        order: 'descend'
    });
    const [phoneNumbers, setPhoneNumbers] = useState({});
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [downloadPages, setDownloadPages] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const hiringManagersRefs = useRef({});

    const fetchMatchedJobs = useCallback((page, pageSize, sortField, sortOrder) => {
        setLoading(true);
        api.getMatchedJobsForSpy(selectedAlert.id, page, pageSize, sortField, sortOrder)
            .then(response => {
                setMatchedJobs(response.jobs);
                setPagination(prev => ({
                    ...prev,
                    current: response.currentPage,
                    total: response.totalJobs
                }));
                setDownloadPages(response.currentPage.toString());
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching matched jobs:', error);
                message.error('Failed to fetch matched jobs');
                setLoading(false);
            });
    }, [selectedAlert]);

    useEffect(() => {
        fetchMatchedJobs(pagination.current, pagination.pageSize, sorter.field, sorter.order);
        const poll = setInterval(() => {
            fetchMatchedJobs(pagination.current, pagination.pageSize, sorter.field, sorter.order);
        }, 30000);
        return () => clearInterval(poll);
    }, [fetchMatchedJobs, pagination.current, pagination.pageSize, sorter.field, sorter.order]);

    useEffect(() => {
        refreshBehaviour(() => {
            console.log("Refresh!");
            fetchMatchedJobs(pagination.current, pagination.pageSize, sorter.field, sorter.order);
        });
    }, []);

    const handleTableChange = (newPagination, filters, newSorter) => {
        setPagination(newPagination);
        //console.log(newPagination.current.toString());
        //setDownloadPages(newPagination.current.toString());
        setSorter({
            field: newSorter.field || 'datePosted',
            order: newSorter.order || 'descend'
        });
    };

    const renderDescription = (description) => {
        const cleanedDescription = description.replace(/^```html/, '').replace(/```$/, '').trim();
        return <div dangerouslySetInnerHTML={{ __html: cleanedDescription }} />;
    };

    const renderDatePosted = (datePosted) => {
        return datePosted === 'Unknown' ? 'Very recent' : datePosted;
    };

    const fetchPhoneNumber = async (alertId, managerId) => {
        try {
            const response = await api.getHiringManagerPhoneNumber(alertId, managerId);
            setPhoneNumbers(prev => ({
                ...prev,
                [`${alertId}-${managerId}`]: response.phoneNumber
            }));
            message.success('Phone number retrieved successfully');
        } catch (error) {
            console.error('Error fetching phone number:', error);
            message.error('Failed to retrieve phone number');
        }
    };

    const showDownloadModal = () => {
        console.log("ab " + downloadPages);
        setIsDownloadModalVisible(true);
        //setDownloadPages('');
    };

    const handleDownloadModalCancel = () => {
        if (!isDownloading) {
            setIsDownloadModalVisible(false);
            //setDownloadPages('');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleDownloadIntel();
        }
    };

    const handleDownloadIntel = async () => {
        if (!downloadPages) return;

        setIsDownloading(true);
        try {
            await api.downloadIntel(selectedAlert.id, downloadPages, selectedAlert.industry + "_" + selectedAlert.jobTitle);
            message.success('Intel downloaded successfully');
            setIsDownloadModalVisible(false);
            setDownloadPages('');
            fetchMatchedJobs(pagination.current, pagination.pageSize, sorter.field, sorter.order);
        } catch (error) {
            console.error('Error downloading intel:', error);
            message.error('Failed to download intel');
        } finally {
            setIsDownloading(false);
        }
    };

    const renderHiringManagers = (hiringManagers, alertId) => {
        if (hiringManagers.length === 0) {
            return <p>No hiring managers found.</p>;
        }
        return hiringManagers.map((manager, index) => (
            <div key={index}>
                <p><strong>Name:</strong> {manager.name}</p>
                <p><strong>LinkedIn:</strong> <a href={manager.linkedInUrl} target="_blank" rel="noopener noreferrer">{manager.linkedInUrl}</a></p>
                {manager.email && <p><strong>Email:</strong> {manager.email.toLowerCase()}</p>}
                <p>
                    <strong>Phone:</strong>{' '}
                    {phoneNumbers[`${alertId}-${manager.id}`] ? (
                        phoneNumbers[`${alertId}-${manager.id}`]
                    ) : manager.phoneNumber ? manager.phoneNumber : (
                        userLimits.hiringManagerContactInfo && userLimits.hiringManagerContactInfo.includes('phone') ? (
                            <SpyButton
                                icon={<PhoneOutlined />}
                                onClick={() => fetchPhoneNumber(alertId, manager.id)}
                                size="small"
                            >
                                Get Phone Number
                            </SpyButton>
                        ) : (
                            <span style={{ color: '#ff4d4f' }}>Phone number access not available in your current plan</span>
                        )
                    )}
                </p>
            </div>
        ));
    };

    const markAsViewed = async (record) => {
        try {
            await api.markJobAsViewed(selectedAlert.id, record.id);
            setMatchedJobs(prevJobs =>
                prevJobs.map(job =>
                    job.id === record.id ? { ...job, viewed: true } : job
                )
            );
        } catch (error) {
            console.error('Error marking job as viewed:', error);
            message.error('Failed to mark job as viewed');
        }
    };

    const handleExpand = (expanded, record) => {
        setExpandedRowKeys(keys =>
            expanded
                ? [...keys, record.id]
                : keys.filter(key => key !== record.id)
        );

        if (expanded && !record.viewed) {
            markAsViewed(record);
        }
    };

    const handleHiringManagersClick = (event, record) => {
        event.stopPropagation();
        const expanded = expandedRowKeys.includes(record.id);
        handleExpand(!expanded, record);

        if (!expanded) {
            setTimeout(() => {
                if (hiringManagersRefs.current[record.id]) {
                    hiringManagersRefs.current[record.id].scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    };

    const columns = [
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'viewed',
            render: (viewed) => (
                viewed ?
                    <Tag color="default">Viewed</Tag> :
                    <Tag color="green">New</Tag>
            )
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            sorter: true,
        },
        {
            title: 'Job Title',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
        },
        {
            title: 'Posted Date',
            dataIndex: 'datePosted',
            key: 'datePosted',
            sorter: true,
            render: renderDatePosted
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            sorter: true,
        },
        {
            title: 'Employment Type',
            dataIndex: 'employmentType',
            key: 'employmentType',
            sorter: true,
        },
        {
            title: 'Hiring Managers',
            dataIndex: 'hiringManagers',
            key: 'hiringManagers',
            render: (hiringManagers, record) => (
                <a onClick={(e) => handleHiringManagersClick(e, record)}>
                    {`${hiringManagers.length} manager${hiringManagers.length == 1 ? '' : 's'}`}
                </a>
            )
        },
    ];

    return (
        <>
            <SpyButton
                icon={<RollbackOutlined />}
                onClick={onBackToSpies}
                style={{ marginBottom: '16px' }}
            >
                Back to Spies
            </SpyButton>
            <SpyButton
                icon={<DownloadOutlined />}
                onClick={showDownloadModal}
                style={{ marginBottom: '16px' }}
            >
                Download Intel
            </SpyButton>
            <Table
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                dataSource={matchedJobs}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={pagination}
                onChange={handleTableChange}
                expandedRowKeys={expandedRowKeys}
                onExpand={handleExpand}
                locale={{
                    emptyText: "Your spy is gathering intel!"
                }}
                expandable={{
                    expandedRowRender: (record) => (
                        <div>
                            <p><strong>Description:</strong> {renderDescription(record.description)}</p>
                            <p><strong>Related Spy ID:</strong> {record.alertId}</p>
                            <Divider />
                            <h2 ref={el => hiringManagersRefs.current[record.id] = el}>
                                <strong>Hiring Managers:</strong>
                            </h2>
                            {renderHiringManagers(record.hiringManagers, record.alertId)}
                        </div>
                    )
                }}
            />
            <SpyModal
                title="Download Intel"
                visible={isDownloadModalVisible}
                onOk={handleDownloadIntel}
                onCancel={handleDownloadModalCancel}
                okButtonProps={{ disabled: !downloadPages || isDownloading }}
                cancelButtonProps={{ disabled: isDownloading }}
                closable={!isDownloading}
                maskClosable={!isDownloading}
            >
                <Spin spinning={isDownloading} tip="Generating File...">
                    <p>Enter the pages you want to download (e.g., 1 or 1-5 or 1,5,8):</p>
                    <Input
                        value={downloadPages}
                        onChange={(e) => setDownloadPages(e.target.value)}
                        onKeyPress={handleKeyPress}
                        style={{ width: '100%' }}
                        disabled={isDownloading}
                        placeholder="Enter page numbers"
                    />
                </Spin>
            </SpyModal>
        </>
    );
}

export default SpyIntel;