import React, { useState, useEffect } from 'react';
import { Typography, Card, Space, Button, message } from 'antd';
import api from '../utils/api';

const { Title, Paragraph } = Typography;

const SubscriptionPage = () => {
    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                const data = await api.getUserSubscription();
                setSubscription(data);
            } catch (error) {
                console.error('Error fetching subscription:', error);
                message.error('Failed to load subscription information');
            } finally {
                setLoading(false);
            }
        };

        fetchSubscription();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Space direction="vertical" size="large" style={{ width: '100%', padding: '24px' }}>
            <Title level={2}>Your Subscription</Title>
            <Card>
                <Space direction="vertical">
                    <Paragraph>
                        <strong>Current Plan:</strong> {subscription?.subscriptionPlanName || 'No active subscription'}
                    </Paragraph>
                    {subscription?.isSubscribed && (
                        <Paragraph>
                            <strong>Last Updated:</strong> {new Date(subscription.subscriptionUpdatedAt).toLocaleString()}
                        </Paragraph>
                    )}
                </Space>
            </Card>
            <Paragraph>
                To change your subscription plan, please contact our support team at{' '}
                <a href="mailto:support@webnova.ca">support@webnova.ca</a>.
            </Paragraph>
            <Button type="primary" href="mailto:support@webnova.ca">
                Contact Support
            </Button>
        </Space>
    );
};

export default SubscriptionPage;