import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { message, Spin } from 'antd';
import api from '../utils/api';
import { useUser } from '../UserContext'; // Make sure this path is correct

const SubscriptionSuccess = () => {
    const [verifying, setVerifying] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { updateUser } = useUser();

    useEffect(() => {
        const verifySubscription = async () => {
            try {
                const searchParams = new URLSearchParams(location.search);
                const sessionId = searchParams.get('session_id');

                if (!sessionId) {
                    throw new Error('No session ID found');
                }

                const result = await api.verifySubscription(sessionId);

                if (result.success) {
                    message.success(`Subscription to ${result.planName} successful!`);
                    updateUser({
                        isSubscribed: true,
                        subscriptionPlanName: result.planName,
                        // Add any other relevant user info
                    });
                    navigate('/'); // Redirect to dashboard or appropriate page
                } else {
                    throw new Error('Subscription verification failed');
                }
            } catch (error) {
                console.error('Error verifying subscription:', error);
                message.error('There was an error verifying your subscription. Please contact support.');
                navigate('/'); // Redirect back to pricing page or appropriate error page
            } finally {
                setVerifying(false);
            }
        };

        verifySubscription();
    }, [location, navigate, updateUser]);

    if (verifying) {
        return <Spin tip="Verifying your subscription..." />;
    }

    return null; // This component doesn't render anything as it handles redirect
};

export default SubscriptionSuccess;